import {Field, getIn} from 'formik'
import React from 'react'
import {CheckboxCheck, CheckboxInput, CheckboxWrap, InvalidFeedbck} from '../components/summer-2020-form-sytle'

export const weekdays = {
  0: {
    en: 'Mon',
    ja: '月曜日'
  },
  1: {
    en: 'Tue',
    ja: '火曜日'
  },
  2: {
    en: 'Wed',
    ja: '水曜日'
  },
  3: {
    en: 'Thu',
    ja: '木曜日'
  },
  4: {
    en: 'Fri',
    ja: '金曜日'
  }
}

export const days = {
  week1: {
    0: '6月29日(月)',
    1: '6月30日(火)',
    2: '7月1日(水)',
    3: '7月2日(木)',
    4: '7月3日(金)'
  },
  week2: {
    0: '7月6日(月)',
    1: '7月7日(火)',
    2: '7月8日(水)',
    3: '7月9日(木)',
    4: '7月10日(金)'
  },
  week3: {
    0: '7月13日(月)',
    1: '7月14日(火)',
    2: '7月15日(水)',
    3: '7月16日(木)',
    4: '7月17日(金)'
  },
  week4: {
    0: '7月20日(月)',
    1: '7月21日(火)',
    2: '7月22日(水)',
    3: '7月23日(木)',
    4: '7月24日(金)'
  },
  week5: {
    0: '7月27日(月)',
    1: '7月28日(火)',
    2: '7月29日(水)',
    3: '7月30日(木)',
    4: '7月31日(金)'
  }
}

export const times = {
  1: '9:00 - 9:40',
  2: '9:50 - 10:30',
  3: '10:50 - 11:30',
  4: '13:00 - 15:00'
}

export function getClasses (classes, time, weekday) {
  return classes.filter(i => {
    return i.fields.Time === time && i.fields.Weekday.includes(weekday)
  })
}

export function getClass (id, classes) {
  return classes.filter(i => {
    return i.id === id
  })
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

function getClassPrice (stage, _class) {
  if (!_class) {
    return
  }
  if (stage === '小学部') {
    if (_class.includes('終日')) {
      return 400
    } else if (_class.includes('午前')) {
      return 225
    } else if (_class.includes('午後')) {
      return 175
    } else {
      return 0
    }
  } else if (stage === '幼児部') {
    if (_class.includes('終日')) {
      return 375
    } else if (_class.includes('午前')) {
      return 215
    } else if (_class.includes('午後')) {
      return 160
    } else {
      return 0
    }
  }
}

export function eachAmount (student) {
  const name = student.studentNameJa
  const grade = student.studentGradeInJp || ''
  const classes = student.studentClasses || ''

  if (!grade || !classes) {
    return
  }

  const stage = grade.includes('未就園') ? '未就園' : grade.includes('幼児部') ? '幼児部' : grade.includes('小学部') ? '小学部' : ''

  if (stage === '未就園') {
    const classes = student.studentClassesPreN
    return (
      <div>
        <span>{name}</span><br />
        <span>参加する日</span><br />
        {classes && classes.includes('6月30日') && <div> <span>6月30日</span><br /></div>}
        {classes && classes.includes('7月7日') && <div><span>7月7日</span><br /></div>}
        {classes && classes.includes('7月14日') && <div> <span>7月14日</span><br /></div>}
        {classes && classes.includes('7月21日') && <div> <span>7月21日</span><br /></div>}
        {classes && classes.includes('7月28日') && <div> <span>7月28日</span><br /></div>}

        {classes && `合計: $${(classes.length) * 30}`}<br /><br />
      </div>
    )
  } else if (stage === '幼児部' && classes.week1 && classes.week2 && classes.week3 && classes.week4 && classes.week5) {
    if (classes.week1.includes('終日') && classes.week2.includes('終日') && classes.week3.includes('終日') && classes.week4.includes('終日') && classes.week5.includes('終日')) {
      return (
        <div>
          <span>{name}</span><br />
          <span>全5週・終日: $1828.12</span>
        </div>
      )
    } else if (classes.week1.includes('午前') && classes.week2.includes('午前') && classes.week3.includes('午前') && classes.week4.includes('午前') && classes.week5.includes('午前')) {
      return (
        <div>
          <span>{name}</span><br />
          <span>全5週・午前: $1048.12</span>
        </div>
      )
    } else if (classes.week1.includes('午後') && classes.week2.includes('午後') && classes.week3.includes('午後') && classes.week4.includes('午後') && classes.week5.includes('午後')) {
      return (
        <div>
          <span>{name}</span><br />
          <span>全5週・午後: $780</span>
        </div>
      )
    }
  } else if (stage === '小学部' && classes.week1 && classes.week2 && classes.week3 && classes.week4 && classes.week5) {
    if (classes.week1.includes('終日') && classes.week2.includes('終日') && classes.week3.includes('終日') && classes.week4.includes('終日') && classes.week5.includes('終日')) {
      return (
        <div>
          <span>{name}</span><br />
          <span>全5週・終日: $1950</span>
        </div>
      )
    } else if (classes.week1.includes('午前') && classes.week2.includes('午前') && classes.week3.includes('午前') && classes.week4.includes('午前') && classes.week5.includes('午前')) {
      return (
        <div>
          <span>{name}</span><br />
          <span>全5週・午前: $1096.87</span>
        </div>
      )
    } else if (classes.week1.includes('午後') && classes.week2.includes('午後') && classes.week3.includes('午後') && classes.week4.includes('午後') && classes.week5.includes('午後')) {
      return (
        <div>
          <span>{name}</span><br />
          <span>全5週・午後: $853.12</span>
        </div>
      )
    }
  }
  return (
    <div>
      <span>{name}</span><br />
      {classes.week1 && <div><span>{classes.week1}: {formatter.format(getClassPrice(stage, classes.week1))}</span><br /></div>}
      {classes.week2 && <div><span>{classes.week2}: {formatter.format(getClassPrice(stage, classes.week2))}</span><br /></div>}
      {classes.week3 && <div><span>{classes.week3}: {formatter.format(getClassPrice(stage, classes.week3))}</span><br /></div>}
      {classes.week4 && <div><span>{classes.week4}: {formatter.format(getClassPrice(stage, classes.week4))}</span><br /></div>}
      {classes.week5 && <div><span>{classes.week5}: {formatter.format(getClassPrice(stage, classes.week5))}</span><br /></div>}

      {classes && classes.week1 && classes.week2 && classes.week3 && classes.week4 && classes.week5 && `合計: ${formatter.format(getClassPrice(stage, classes.week1) + getClassPrice(stage, classes.week2) + getClassPrice(stage, classes.week3) + getClassPrice(stage, classes.week4) + getClassPrice(stage, classes.week5))}`}
    </div>
  )
}

export function totalAmount (student) {
  const grade = student.studentGradeInJp || ''
  const classes = student.studentClasses || ''

  if (!grade || !classes) {
    return
  }

  const stage = grade.includes('未就園') ? 'preN' : grade.includes('幼児部') ? '幼児部' : grade.includes('小学部') ? '小学部' : ''

  if (stage === '小学部' || stage === '幼児部') {
    return (
      <div>
        {classes.week1 && <div><span>{classes.week1}: ${getClassPrice(stage, classes.week1)}</span><br /></div>}
        {classes.week2 && <div><span>{classes.week2}: ${getClassPrice(stage, classes.week2)}</span><br /></div>}
        {classes.week3 && <div><span>{classes.week3}: ${getClassPrice(stage, classes.week3)}</span><br /></div>}
        {classes.week4 && <div><span>{classes.week4}: ${getClassPrice(stage, classes.week4)}</span><br /></div>}
        {classes.week5 && <div><span>{classes.week5}: ${getClassPrice(stage, classes.week5)}</span><br /></div>}
      </div>
    )
  }
}

export function totalClasses (students) {
  let amount = 0
  students.map(el => {
    el.studentClasses.map(cl => {
      amount++
    })
  })
  return amount
}

export function Checkbox (props) {
  return (
    <Field name={props.name}>
      {({field, form}) => (
        <CheckboxWrap className={props.wrapperClass}>
          <CheckboxInput
            type='checkbox'
            {...props}
            checked={field.value.includes(props.value)}
            onChange={() => {
              if (field.value.includes(props.value)) {
                const nextValue = field.value.filter(
                  value => value !== props.value
                )
                form.setFieldValue(props.name, nextValue)
              } else {
                const nextValue = field.value.concat(props.value)
                form.setFieldValue(props.name, nextValue)
              }
            }}
          />
          {props.value}
          <CheckboxCheck className='form-control-check' />
        </CheckboxWrap>
      )}
    </Field>
  )
}

export function ErrorMessage ({name}) {
  return (
    <Field
      name={name}
      render={({form}) => {
        const error = getIn(form.errors, name)
        const touch = getIn(form.touched, name)
        return touch && error ? <InvalidFeedbck>{error}</InvalidFeedbck> : null
      }}
    />
  )
}

export function WeekLabel (num) {
  if (num === 0) {
    return '1週目: 6月29日(月) ~ 7月3日(金) お申し込み終了'
  } else if (num === 1) {
    return '2週目: 7月6日(月) ~ 7月10日(金) お申し込み終了'
  } else if (num === 2) {
    return '3週目: 7月13日(月) ~ 7月17日(金) お申し込み終了'
  } else if (num === 3) {
    return '4週目: 7月20日(月) ~ 7月24日(金) お申し込み終了'
  } else if (num === 4) {
    return '5週目: 7月27日(月) ~ 7月31日(金)'
  }
  return ''
}
