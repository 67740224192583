import React from 'react'
import Container from '../../../components/container'
import GraphQLErrorList from '../../../components/graphql-error-list'
import Heading from '../../../components/heading'
import Seo from '../../../components/seo'
import Summer2020ExchangeClass from '../../../components/summer-2020-exchange-class-form'
import Layout from '../../../containers/layout'

const ExchangeClass = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout isHome>
      <Seo />
      <Container>
        <Summer2020ExchangeClass />
      </Container>
    </Layout>
  )
}

export default ExchangeClass
