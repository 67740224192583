import axios from 'axios'
import {withFormik} from 'formik'
import React from 'react'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {Debug} from '../lib/formik-debug'
import {ErrorMessage, getClasses} from '../lib/helpers'
import {Button, ButtonWrapper, Desc, Form, FormGroup, Heading, Input, InputWrap, Label, Redirect, StyledMaskedInput} from './summer-2020-form-sytle'
import Summer2020ExchangeClassForm2 from './summer-2020-exchange-class-form2'
import Toastify from './Toastify'

const notifyError = () => toast.error('お申し込み番号をご確認の上、\nページを再読込してから送信してください。', {containerId: 'Error'})

const applicationNumMask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

const InnerForm = props => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit
  } = props
  return (
    <>
      <Form onSubmit={handleSubmit} key='getClass'>
        <Heading>登録可能・登録済みクラスを検索</Heading>

        <FormGroup className='applicationNum'>
          <InputWrap>
            <Input
              name='applicationNum'
              render={({field}) => (
                <StyledMaskedInput
                  {...field}
                  mask={applicationNumMask}
                  id='phone'
                  type='text'
                  value={values.applicationNum}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${touched.applicationNum ? 'is-touched' : ''} ${touched.applicationNum && errors.applicationNum ? 'is-invalid' : 'is-valid'} ${touched.applicationNum && values.applicationNum === '' ? 'is-empty' : ''}`}
                />
              )}
            />
            <Label className='form-control-label'>お申し込み番号</Label>
          </InputWrap>
          <Desc>16桁のお申し込み番号を半角数字で入力してください。</Desc>
          <ErrorMessage name='name' />
        </FormGroup>

        <ButtonWrapper>
          <Button
            type='submit'
            className='btn btn-outline-primary'
            disabled={isSubmitting}
          >
            {isSubmitting ? '送信中...' : 'クラスを表示する'}
          </Button>
          {isSubmitting && <Redirect>クラス情報の取得中です。そのままお待ちください。</Redirect>}
        </ButtonWrapper>

        {/* <Debug /> */}

        <Toastify
          enableMultiContainer
          containerId='Success'
          position='top-right'
          autoClose={8000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          pauseOnHover
          className='myToast'
        />
        <Toastify
          enableMultiContainer
          containerId='Error'
          position='top-right'
          autoClose={8000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          pauseOnHover
          className='myToast'
        />

      </Form>
      {values.registered &&
        <Summer2020ExchangeClassForm2
          applicationNum={values.applicationNum}
          student={values.student}
          classes={values.classes}
          studentId={values.studentId}
          meetUrl={values.meetUrl}
          currentClasses={values.currentClasses}
        />}
    </>
  )
}

const Summer2020ExchangeClassForm = withFormik({
  mapPropsToValues: () => ({
    applicationNum: '',
    studentId: '',
    student: {},
    classes: '',
    registered: false,
    meetUrl: []
  }),

  handleSubmit: async (values, {props, setValues, setSubmitting, setStatus, validateForm}) => {
    await validateForm().then(res => {
      setStatus({state: 'Submitting'})
      axios.request({
        method: 'POST',
        baseURL: '/api/exchangeClass/getClasses',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        data: JSON.stringify({
          applicationNum: values.applicationNum
        })
      })
        .then((res) => {
          console.log(res.data)
          setValues({...values, studentId: res.data.id, student: res.data.studentInfo, classes: res.data.classes, currentClasses: res.data.currentClasses, registered: res.data.registered, meetUrl: res.data.meetUrl})
          setSubmitting(false)
          setStatus({state: 'Success'})
        }).catch((error) => {
          console.log(error)
          notifyError()
          setSubmitting(false)
          setStatus({state: 'Error'})
        })
    }).catch(err => {
      console.log(err)
    })
  },

  validationSchema: Yup.object().shape({
    applicationNum: Yup.string().matches(/^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}$/, '16桁のお申し込み番号を入力してください')
  })
})(InnerForm)

export default Summer2020ExchangeClassForm
