export const gender = [
  {value: '男'},
  {value: '女'},
  {value: 'その他'},
  {value: '無回答'}
]

export const payment = [
  {value: 'クレジットカード', label: 'クレジットカード'},
  {value: 'チェック', label: 'チェック郵送'}
]

export const yesNo = [
  {value: 'true', label: 'はい'},
  {value: 'false', label: 'いいえ'}
]

export const newsletter = [
  {value: 'はい', label: 'はい'},
  {value: 'いいえ', label: 'いいえ'},
  {value: '登録済み', label: '登録済み'}
]

export const gradesInJp = [
  {value: '未就園', label: '未就園'},
  {value: '幼児部年少', label: '幼児部年少(3歳の未就園児も可)'},
  {value: '幼児部年中', label: '幼児部年中'},
  {value: '幼児部年長', label: '幼児部年長'},
  {value: '小学部一年', label: '小学部一年'},
  {value: '小学部二年', label: '小学部二年'},
  {value: '小学部三年', label: '小学部三年'},
  {value: '小学部四年', label: '小学部四年'},
  {value: '小学部五年', label: '小学部五年'},
  {value: '小学部六年', label: '小学部六年'}
]

export const gradesInUs = [
  {value: 'Pre-Nursery', label: 'Pre-Nursery(3歳未満)'},
  {value: 'Nursery', label: 'Nursery(3-4歳)'},
  {value: 'Pre-K', label: 'Pre-K (4-5歳)'},
  {value: 'Kindergarten', label: 'Kindergarten(5-6歳)'},
  {value: '1st Grade', label: '1st Grade'},
  {value: '2nd Grade', label: '2nd Grade'},
  {value: '3rd Grade', label: '3rd Grade'},
  {value: '4th Grade', label: '4th Grade'},
  {value: '5th Grade', label: '5th Grade'},
  {value: '6th Grade', label: '6th Grade'}
]

export const classesElementary = [
  [{label: '終日', value: '終日'}, {label: '午前', value: '午前'}, {label: '午後', value: '午後'}, {label: 'なし', value: 'なし'}],
  [{label: '終日', value: '終日'}, {label: '午前', value: '午前'}, {label: '午後', value: '午後'}, {label: 'なし', value: 'なし'}],
  [{label: '終日', value: '終日'}, {label: '午前', value: '午前'}, {label: '午後', value: '午後'}, {label: 'なし', value: 'なし'}],
  [{label: '終日', value: '終日'}, {label: '午前', value: '午前'}, {label: '午後', value: '午後'}, {label: 'なし', value: 'なし'}],
  [{label: '終日', value: '終日'}, {label: '午前', value: '午前'}, {label: '午後', value: '午後'}, {label: 'なし', value: 'なし'}]
]

export const classesPreNursery = [
  // {value: '6月30日'},
  // {value: '7月7日'},
  // {value: '7月14日'},
  // {value: '7月21日'},
  {value: '7月28日'}
]

export const tshirts = [
  {value: 'XS', label: 'XS - 園児向け'},
  {value: 'S', label: 'S - 米国子供サイズ 6-8'},
  {value: 'M', label: 'M - 米国子供サイズ 10-12'},
  {value: 'L', label: 'L - 米国子供サイズ 14-16'}
]

export const languageClass = [
  {value: '英語', label: '英語'},
  {value: '日本語', label: '日本語'}
]

export const languageSkill = [
  {value: '日常的に使用している', label: '日常的に使用している'},
  {value: '簡単な話ができる', label: '簡単な話ができる'},
  {value: 'ほとんど話せない', label: 'ほとんど話せない'}
]
