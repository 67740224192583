import axios from 'axios'
import styled from 'styled-components'
import {Field, FieldArray, getIn, withFormik} from 'formik'
import React from 'react'
import {toast} from 'react-toastify'
import useSSR from 'use-ssr'
import * as Yup from 'yup'
import {Debug} from '../lib/formik-debug'
import {classesPreNursery, classesElementary, gender, gradesInJp, gradesInUs, languageClass, languageSkill, newsletter, tshirts, yesNo} from '../lib/summer2020'
import {Heading, SubHeading, Form, FormGroup, Student, Birthday, Small, Desc, InputWrap, SelectWrap, Label, Input, Select, CheckboxWrap, CheckboxInput, CheckboxCheck, RadioWrap, Radio, RadioInput, Textarea, InvalidFeedbck, ButtonWrapper, Button, ButtonRemove, Message, Error, Amounts, TotalClasses, TotalAmount, HowtoPay, Redirect, Attention, StyledLink, TermsLink, ExternalLink, Week, WeekRow, Pricing, StyledMaskedInput} from './summer-2020-form-sytle'
import Toastify from './Toastify'
import {colors, font, fontfamily} from '../lib/variables'

import {ErrorMessage, getClasses, weekdays, days, times} from '../lib/helpers'

const recaptchaRef = React.createRef()

const notifyError = () => toast.error('お申し込み番号をご確認の上、\nページを再読込してから送信してください。', {containerId: 'Error'})

const InnerForm = props => {
  const {
    applicationNum,
    student,
    classes,
    values,
    touched,
    status,
    errors,
    setStatus,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    submitCount,
    setSubmitting,
    setFieldValue,
    setValues
  } = props
  return (
    <Form onSubmit={handleSubmit} key='registration'>
      {student.name && (
        <>
          <Heading>クラスを登録</Heading>
          <Day>登録する学年・週・時間帯</Day>

          <StudentInfos>
            <StudentInfo>学年: {student.grade}</StudentInfo>
            <StudentInfo>参加週: {student.week}</StudentInfo>
            <StudentInfo>参加時間帯: {student.class}</StudentInfo>
          </StudentInfos>
        </>
      )}

      {classes && (student.class === '午前' || student.class === '終日') && (
        Array.from({length: 5}).map((_, i) => {
          return (
            <div key={i}>
              <Day>{days[student.week][i]}</Day>
              {
                Array.from({length: 3}).map((_, j) => {
                  return (
                    <DayWrap key={j}>
                      <FormGroup>
                        <SelectWrap>
                          <Select
                            name={`classes.${weekdays[i].en}.time${j + 1}`}
                            className={`form-control is-touched ${getIn(touched, `classes.${weekdays[i].en}.time${j + 1}`) && getIn(errors, `classes.${weekdays[i].en}.time${j + 1}`) ? 'is-invalid' : 'is-valid'} ${getIn(touched, `classes.${weekdays[i].en}.time${j + 1}`) && values.classes[weekdays[i].en]['time' + (j + 1)] === '' ? 'is-empty' : ' '}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.classes[weekdays[i].en]['time' + (j + 1)]}
                          >
                            <option value='' label='' />
                            {getClasses(classes, (j + 1).toString(), weekdays[i].en).map(el => {
                              return (
                                <option
                                  key={el.id}
                                  value={el.id}
                                  label={`${el.fields['Limit Status']}: ${el.fields['ID - Name']}`}
                                >
                                  {`${el.fields['Limit Status']}: ${el.fields['ID - Name']}`}
                                </option>)
                            })}
                            <optgroup label='' />
                          </Select>
                          <Label className='form-control-label'>{times[j + 1]}</Label>
                        </SelectWrap>
                        <ErrorMessage name={`classes.${weekdays[i].en}.time${j + 1}`} />
                      </FormGroup>
                    </DayWrap>
                  )
                })
              }
            </div>
          )
        })
      )}

      {classes && (student.class === '午後' || student.class === '終日') && (
        <FormGroup>
          <Day>午後の授業(月~金共通)</Day>
          <SelectWrap>
            <Select
              name='classes.afternoon'
              className={`form-control is-touched ${getIn(touched, 'classes.afternoon') && getIn(errors, 'classes.afternoon') ? 'is-invalid' : 'is-valid'} ${getIn(touched, 'classes.afternoon') && values.classes.afternoon === '' ? 'is-empty' : ' '}`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.classes.afternoon}
            >
              <option value='' label='' />
              {getClasses(classes, '4', 'Mon').map(el => {
                return (
                  <option
                    key={el.id}
                    value={el.id}
                    label={`${el.fields['Limit Status']}: ${el.fields['ID - Name']}`}
                  >
                    {`${el.fields['Limit Status']}: ${el.fields['ID - Name']}`}
                  </option>)
              })}
              <optgroup label='' />
            </Select>
            <Label className='form-control-label'>13:00 - 15:00</Label>
          </SelectWrap>
          <ErrorMessage name='classes.afternoon' />
        </FormGroup>
      )}
      {classes &&
        <ButtonWrapper>
          <Button
            type='submit'
            className='btn btn-outline-primary'
            disabled={isSubmitting || (status && status.state === 'Success')}
          >
            {isSubmitting ? '送信中...' : (status && status.state === 'Success') ? '登録完了' : '登録する'}
          </Button>
          {isSubmitting && <Redirect>登録中です。そのままお待ちください。</Redirect>}
          {(status && status.state === 'Success') && <Redirect>このページにお問い合わせ番号を入力して登録済みのクラスが確認できます。</Redirect>}
        </ButtonWrapper>}
      {/* <Debug /> */}

      <Toastify
        enableMultiContainer
        containerId='Success'
        position='top-right'
        autoClose={8000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        pauseOnHover
        className='myToast'
      />
      <Toastify
        enableMultiContainer
        containerId='Error'
        position='top-right'
        autoClose={8000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        pauseOnHover
        className='myToast'
      />

    </Form>
  )
}

const Summer2020RegistrationForm = withFormik({
  mapPropsToValues ({currentClasses, student}) {
    const morning = (student.class.includes('午前') || student.class.includes('終日'))
    const afternoon = (student.class.includes('午後') || student.class.includes('終日'))
    return {
      applicationNum: '',
      studentId: '',
      classes: {
        Mon: {
          time1: (morning && currentClasses[0]) || '',
          time2: (morning && currentClasses[1]) || '',
          time3: (morning && currentClasses[2]) || ''
        },
        Tue: {
          time1: (morning && currentClasses[3]) || '',
          time2: (morning && currentClasses[4]) || '',
          time3: (morning && currentClasses[5]) || ''
        },
        Wed: {
          time1: (morning && currentClasses[6]) || '',
          time2: (morning && currentClasses[7]) || '',
          time3: (morning && currentClasses[8]) || ''
        },
        Thu: {
          time1: (morning && currentClasses[9]) || '',
          time2: (morning && currentClasses[10]) || '',
          time3: (morning && currentClasses[11]) || ''
        },
        Fri: {
          time1: (morning && currentClasses[12]) || '',
          time2: (morning && currentClasses[13]) || '',
          time3: (morning && currentClasses[14]) || ''
        },
        afternoon: (afternoon && currentClasses.slice(-1)[0]) || ''
      }
    }
  },

  handleSubmit: async (values, {props, setValues, setSubmitting, setStatus, validateForm}) => {
    await validateForm().then(res => {
      console.log(props)
      setValues({...values, applicationNum: props.applicationNum, studentId: props.studentId})
      setStatus({state: 'Submitting'})
      axios.request({
        method: 'POST',
        baseURL: '/api/exchangeClass/newClassIds',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        data: JSON.stringify({
          applicationNum: props.applicationNum,
          studentId: props.studentId,
          classes: values.classes,
          classList: props.classes
        })
      })
        .then((res) => {
          alert(res.data)
          setSubmitting(false)
          setStatus({state: 'Success'})
        }).catch((error) => {
          console.log(error)
          notifyError()
          setSubmitting(false)
          setStatus({state: 'Error'})
        })
    }).catch(err => {
      console.log(err)
    })
  },

  validationSchema: props => {
    const {student} = props
    if (student.class === '午前') {
      return Yup.object().shape({
        classes: Yup.object().shape({
          Mon: Yup.object().shape({
            time1: Yup.string().required('授業を選択してください。'),
            time2: Yup.string().required('授業を選択してください。'),
            time3: Yup.string().required('授業を選択してください。')
          }),
          Tue: Yup.object().shape({
            time1: Yup.string().required('授業を選択してください。'),
            time2: Yup.string().required('授業を選択してください。'),
            time3: Yup.string().required('授業を選択してください。')
          }),
          Wed: Yup.object().shape({
            time1: Yup.string().required('授業を選択してください。'),
            time2: Yup.string().required('授業を選択してください。'),
            time3: Yup.string().required('授業を選択してください。')
          }),
          Thu: Yup.object().shape({
            time1: Yup.string().required('授業を選択してください。'),
            time2: Yup.string().required('授業を選択してください。'),
            time3: Yup.string().required('授業を選択してください。')
          }),
          Fri: Yup.object().shape({
            time1: Yup.string().required('授業を選択してください。'),
            time2: Yup.string().required('授業を選択してください。'),
            time3: Yup.string().required('授業を選択してください。')
          })
        })
      })
    } else if (student.class === '午後') {
      return Yup.object().shape({
        classes: Yup.object().shape({
          afternoon: Yup.string().required('授業を選択してください。')
        })
      })
    } else if (student.class === '終日') {
      return Yup.object().shape({
        classes: Yup.object().shape({
          Mon: Yup.object().shape({
            time1: Yup.string().required('授業を選択してください。'),
            time2: Yup.string().required('授業を選択してください。'),
            time3: Yup.string().required('授業を選択してください。')
          }),
          Tue: Yup.object().shape({
            time1: Yup.string().required('授業を選択してください。'),
            time2: Yup.string().required('授業を選択してください。'),
            time3: Yup.string().required('授業を選択してください。')
          }),
          Wed: Yup.object().shape({
            time1: Yup.string().required('授業を選択してください。'),
            time2: Yup.string().required('授業を選択してください。'),
            time3: Yup.string().required('授業を選択してください。')
          }),
          Thu: Yup.object().shape({
            time1: Yup.string().required('授業を選択してください。'),
            time2: Yup.string().required('授業を選択してください。'),
            time3: Yup.string().required('授業を選択してください。')
          }),
          Fri: Yup.object().shape({
            time1: Yup.string().required('授業を選択してください。'),
            time2: Yup.string().required('授業を選択してください。'),
            time3: Yup.string().required('授業を選択してください。')
          }),
          afternoon: Yup.string().required('授業を選択してください。')
        })
      })
    }
  }
})(InnerForm)

const StudentInfos = styled.div`
  display: block;
  margin: 1.6rem 0 4rem 0
`

const StudentInfo = styled.span`
  display: block;
  font-size: 1.6rem;
  margin: 0 0 0.8rem;
`

const DayWrap = styled.div`
  margin: 0 0 4rem 0;
`

const Day = styled.span`
  font-size: 1.8rem;
  font-weight: 700;
  color: ${colors.navy};
  margin: 0 0 0.8rem 0;
  display: block;
`

const Weekday = styled.span`
  display: block;
  font-size: 1.6rem;
  margin: 0 0 0.8rem;
  font-weight: 700;
`

export default Summer2020RegistrationForm
